import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Expertise from "../views/Expertise.vue";
import ExpertiseDetail from "../views/ExpertiseDetail.vue";
import VehicleInstanceUpdate from "../views/VehicleInstanceUpdate.vue";
import Icons from "../views/Icons.vue";
import Maps from "../views/Maps.vue";
import User from "../views/User.vue";
import Branch from "../views/Branch.vue";
import Tables from "../views/Tables.vue";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/expertise",
        name: "EKSPERTİZ",
        components: { default: Expertise },
      },
      {
        path: "/expertise/:id",
        name: "EKSPERTİZ DETAY",
        components: { default: ExpertiseDetail },
      },
      {
        path: "/vehicle/instance/update",
        name: "ARAÇ BİLGİLERİNİ GÜNCELLE",
        components: { default: VehicleInstanceUpdate },
      },
      {
        path: "/icons",
        name: "icons",
        components: { default: Icons },
      },
      {
        path: "/maps",
        name: "maps",
        components: { default: Maps },
      },
      {
        path: "/user",
        name: "KULLANICI YÖNETİMİ",
        components: { default: User },
      },
      {
        path: "/branch",
        name: "ŞUBE YÖNETİMİ",
        components: { default: Branch },
      },
      {
        path: "/tables",
        name: "tables",
        components: { default: Tables },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  // Define public routes
  const publicPages = ["/login"];

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
