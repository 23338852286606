<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <Form @submit="handleLogin" :validation-schema="schema">
            <Field name="username" type="text" placeholder="GSM (Başında 0 olmadan)" class="input-group-alternative mb-3 form-control" />
            <ErrorMessage name="username" class="error-feedback" />


            <Field name="password" type="password" placeholder="Parola" class="input-group-alternative mb-3 form-control" />
            <ErrorMessage name="password" class="error-feedback" />

            <div class="text-center">
              <button class="btn btn-primary btn-block my-4" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span>Oturum Aç</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("GSM zorunludur"),
      password: yup.string().required("Parola zorunludur"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.error_description) ||
            error.message ||
            error.toString();

          this.$swal(
            'Hata',
            this.message,
            'error');
        }
      );
    },
  },
};
</script>
<style></style>
