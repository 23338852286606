import api from './api';
import TokenService from "./token.service";

const API_URL = process.env.VUE_APP_API;

class AuthService {
  login(user) {
    return api
      .post(API_URL + "/oauth/v2/token", {
        username: user.username,
        password: user.password,
        grant_type: 'password',
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  logout() {
    TokenService.removeUser();
  }
}

export default new AuthService();
