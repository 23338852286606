import api from './api';

class LocationService {
  getCities() {
    return api.get('/api/cities');
  }
  getCounties(cityId) {
    return api.get('/api/cities/' + cityId  + '/counties');
  }
}

export default new LocationService();