import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));
const member = JSON.parse(localStorage.getItem("member"));
const initialState = user
  ? { status: { loggedIn: true }, user, member }
  : { status: { loggedIn: false }, user: null, member: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);

          UserService.get().then(
            (member) => {
              localStorage.setItem("member", JSON.stringify(member.data));
              commit("updateMember", member.data);
          });

          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    refreshToken({ commit }, refreshToken) {
      commit('refreshToken', refreshToken);
    },
    accessToken({ commit }, accessToken) {
      commit('accessToken', accessToken);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    updateMember(state, member) {
      state.member = member;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    refreshToken(state, refreshToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, refresh_token: refreshToken };
    },
    accessToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, access_token: accessToken };
    },
  },
};
