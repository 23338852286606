import api from './api';

class ExpertiseService {
  getAll(per_page = null, page = null, plate_number = null, start_date = null, end_date = null, status = null, customer_company_id = null, otonet_transfer = null) {
    return api.get('/api/vehicles/instances', {
      params: {
        per_page: per_page,
        page: page,
        plate_number: plate_number,
        start_date: start_date,
        end_date: end_date,
        status: status,
        customer_company_id: customer_company_id,
        otonet_transfer: otonet_transfer
      }
    });
  }
  getAllExcel(plate_number = '', start_date = '', end_date = '', customer_company_id = '') {
    const user = JSON.parse(localStorage.getItem("user"));

    window.open(process.env.VUE_APP_API + "/api/vehicles/instances?access_token=" + user?.access_token + '&excel_export=1&plate_number=' + plate_number + '&start_date=' + start_date + '&end_date=' + end_date + '&customer_company_id=' + customer_company_id);

    return Promise.resolve("done");
  }
  otonetTransfer(id) {
    return api.post('/api/vehicles/instances/' + id + '/reports/transfer/otonet');
  }
  get(id) {
    return api.get('/api/vehicles/instances/' + id);
  }
  getReport(id) {
    return api.get('/api/vehicles/instances/' + id + '/reports');
  }
  getBoardPhotos(id) {
    return api.get('/api/vehicles/instances/' + id + '/board-photos');
  }
  getPartPhotos(id) {
    return api.get('/api/vehicles/instances/' + id + '/part-photos');
  }
  editExpertise(id, model, vin, engine_number) {
    return api.put('/api/vehicles/instances/' + id +'/vehicle', {
        model: model,
        vin: vin,
        engine_number: engine_number
    });
  }
  editPlateNumber(id, plate_number) {
    return api.patch('/api/vehicles/instances/' + id +'', {
        field: 'plate-number',
        value: plate_number
    });
  }
  importVehicleInstance(file) {
    const params= new FormData()
    params.append('file', file['file'][0]);

    return api.post('/api/vehicles/instance/import', params);
  }
  revertStatus(id) {
    return api.patch('/api/vehicles/instances/' + id +'', {
        field: 'status',
        value: 'pending'
    });
  }
}

export default new ExpertiseService();