<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        
      </div>
    </base-header>
    <div class="container-fluid mt--7">
    <div class="row">
      <div class="col">
        <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
          <div
            class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent' : ''"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Şube Listesi
                </h3>
              </div>
              <div class="col text-right">
                <base-button type="primary" size="sm" @click="initModalCreate()">Ekle</base-button>
              </div>
            </div>
          </div>

          <div class="table-responsive table-overflowed">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableData"
            >
              <template v-slot:columns>
                <th>Şube Adı</th>
                <th>İl</th>
                <th>İlçe</th>
                <th></th>
              </template>

              <template v-slot:default="row">
                <th scope="row">
                  {{ row.item.name }}
                </th>
                <td>
                  {{ row.item.city_name }}
                </td>
                <td>
                  {{ row.item.county_name }}
                </td>
                <td class="text-right">
                  <base-dropdown class="dropdown" position="right">
                    <template v-slot:title>
                      <a
                        class="btn btn-sm btn-icon-only text-light"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </a>
                    </template>

                    <a class="dropdown-item" @click="initModalEdit(row.item)">Düzenle</a>
                    <a class="dropdown-item" href="#" @click="removeBranch(row.item.id)">Kaldır</a>
                  </base-dropdown>
                </td>
              </template>
            </base-table>
            <modal v-model:show="modals.edit">
               <template v-slot:header>
                  <h5 class="modal-title">Şube Düzenle</h5>
               </template>
               <div>
                <form role="form">
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Ad"
                    v-model="forms.edit.name"
                  >
                  </base-input>
                  <select class="custom-select custom-select-lg mb-3" v-model="forms.edit.city_id" @change="onChangeCity($event)">
                    <option>Seçiniz</option>
                    <option v-for="city in cities" :key="city.id" v-bind:value="city.id">{{city.name}}</option>
                  </select>
                  <select class="custom-select custom-select-lg mb-3" v-model="forms.edit.county_id">
                    <option>Seçiniz</option>
                    <option v-for="county in counties" :key="county.id" v-bind:value="county.id">{{county.name}}</option>
                  </select>
                </form>
               </div>
               <template v-slot:footer>
                 <base-button type="secondary" @click="modals.edit = false">Kapat</base-button>
                 <base-button type="primary" @click="submitEditForm()">Güncelle</base-button>
               </template>
            </modal>
            <modal v-model:show="modals.create">
               <template v-slot:header>
                  <h5 class="modal-title">Şube Ekle</h5>
               </template>
               <div>
                <form role="form">
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Ad"
                    v-model="forms.create.name"
                  >
                  </base-input>
                  <select class="custom-select custom-select-lg mb-3" v-model="forms.create.city_id" @change="onChangeCity($event)" placeholder="İl seçiniz">
                    <option v-for="city in cities" :key="city.id" v-bind:value="city.id">{{city.name}}</option>
                  </select>
                  <select class="custom-select custom-select-lg mb-3" v-model="forms.create.county_id">
                    <option>Seçiniz</option>
                    <option v-for="county in counties" :key="county.id" v-bind:value="county.id">{{county.name}}</option>
                  </select>
                </form>
               </div>
               <template v-slot:footer>
                 <base-button type="secondary" @click="modals.create = false">Kapat</base-button>
                 <base-button type="primary" @click="submitCreateForm()">Ekle</base-button>
               </template>
            </modal>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import BranchService from "../services/branch.service";
import LocationService from "../services/location.service";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    title: String
  },
  components: {
    Modal
  },
  created: function () {
    BranchService.get().then(
      (branches) => {
        this.tableData = branches.data;
    });
    LocationService.getCities().then(
      (cities) => {
        this.cities = cities.data;
    });
  },
  data() {
    return {
      tableData: [],
      cities: [],
      counties: [],
      modals: {
        create: false,
        edit: false
      },
      forms: {
        create: {
          name: null,
          city_id: null,
          county_id: null
        },
        edit: {
          id: null,
          name: null,
          city_id: null,
          county_id: null
        }
      }
    };
  }, 
  methods: {
    initModalCreate() {
      this.counties = [];
      this.modals.create = true;
    },
    initModalEdit(item) {
      this.modals.edit = true;

      LocationService.getCounties(item.city_id).then(
        (counties) => {
          this.counties = counties.data;
      });

      this.forms.edit = item;
    },
    onChangeCity(event) {
      LocationService.getCounties(event.target.value).then(
        (counties) => {
          this.counties = counties.data;
      });
    },
    submitEditForm() {
      BranchService.update(this.forms.edit).then(
        (response) => {
          this.$swal(
            'Başarılı',
            response.message,
            'success');

          this.modals.edit = false;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
      });
    },
    submitCreateForm() {
      BranchService.create(this.forms.create).then(
        (response) => {
          this.$swal(
            'Başarılı',
            response.message,
            'success');

          BranchService.get().then(
            (branches) => {
              this.tableData = branches.data;
          });

          this.modals.create = false;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
      });
    },
    removeBranch(id) {
      BranchService.remove(id).then(
        (response) => {
          this.$swal(
            'Başarılı',
            response.message,
            'success');

          BranchService.get().then(
            (branches) => {
              this.tableData = branches.data;
          });

          this.modals.create = false;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
      });
    }
  }
};
</script>
<style></style>
