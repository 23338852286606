<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <div class="form-group mb-0">
      </div>
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  src="/img/theme/team-4-800x800.jpg"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block cursor-pointer">
                <span class="mb-0 text-sm font-weight-bold" v-if="member">{{member.name}} {{member.surname}}</span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">HOŞGELDİNİZ!</h6>
          </div>
          <div class="dropdown-divider"></div>
          <a to="#" class="dropdown-item cursor-pointer" @click="initModalEdit()">
            <i class="ni ni-lock-circle-open"></i>
            <span>Parola Değiştir</span>
          </a>
          <div class="dropdown-divider"></div>
          <a to="#" class="dropdown-item cursor-pointer" @click="logout()">
            <i class="ni ni-user-run"></i>
            <span>Çıkış Yap</span>
          </a>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
  <modal v-model:show="modals.edit">
     <template v-slot:header>
        <h5 class="modal-title">Parola Değiştir</h5>
     </template>
     <div>
      <Form @submit="submitEditForm" :validation-schema="schema">
        <div class="d-flex">
          <Field name="password" :type="type" v-model="forms.edit.password" placeholder="Mevcut Parola" class="input-group-alternative mb-3 form-control" />
          <base-button type="secondary px-3 mb-3"  @click="showPassword()"><i :class="hidden"></i></base-button>
        </div>
        <ErrorMessage name="password" class="error-feedback" />
        <div class="d-flex">
          <Field name="new_password" :type="type1" v-model="forms.edit.new_password" placeholder="Yeni Parola" class="input-group-alternative mb-3 form-control" />
          <base-button type="secondary px-3 mb-3" style="height: 43px;" @click="showPassword1()"><i :class="hidden1"></i></base-button>
        </div>
        <ErrorMessage name="new_password" class="error-feedback" />
        <div class="d-flex">
          <Field name="new_password_repeat" :type="type2" v-model="forms.edit.new_password_repeat" placeholder="Yeni Parola Tekrar" class="input-group-alternative mb-3 form-control" />
          <base-button type="secondary px-3 mb-3" style="height: 43px;" @click="showPassword2()"><i :class="hidden2"></i></base-button>
        </div>
        <ErrorMessage name="new_password_repeat" class="error-feedback" />
        <div class="text-center">
          <button class="btn btn-primary btn-block my-4" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Güncelle</span>
          </button>
        </div>
      </Form>
     </div>
  </modal>
</template>
<script>
import {computed} from 'vue';
import {useStore} from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import UserService from "../services/user.service";

import AuthService from "../services/auth.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      password: yup.string().required("Mevcut parola zorunludur.").nullable(),
      new_password: yup.string().required("Yeni parola zorunludur.").nullable(),
      new_password_repeat: yup.string().required("Yeni parola tekrarı zorunludur.").nullable(),
    });
    const store = useStore();

    let member = computed(function () {
      return store.state.auth.member
    });

    return {
      schema,
      loading: false,
      passwordVisible: false,
      hidden: "ni ni-key-25",
      hidden1: "ni ni-key-25",
      hidden2: "ni ni-key-25",
      type: "password",
      type1: "password",
      type2: "password",
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      member,
      modals: {
        edit: false
      },
      forms: {
        edit: {
          password: null,
          new_password: null,
          new_password_repeat: null,
        }
      },
    };
  },
  methods: {
    logout() {
      AuthService.logout()
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    initModalEdit() {
        this.modals.edit = true;
    },
    checkForm() {
      if (this.forms.edit.new_password != this.forms.edit.new_password_repeat) {
        return ;
      } 
      if (this.forms.edit.password && this.forms.edit.new_password && this.forms.edit.new_password_repeat ) {
        this.methods.submitEditForm();
      }

    },
    showPassword() {
       if (this.type === "password") {
          this.type = "text";
          this.hidden = "ni ni-lock-circle-open";
       } else {
          this.type = "password";
          this.hidden = "ni ni-key-25";
       }
    },
    showPassword1() {
       if (this.type1 === "password") {
          this.type1 = "text";
          this.hidden1 = "ni ni-lock-circle-open";
       } else {
          this.type1 = "password";
          this.hidden1 = "ni ni-key-25";
       }
    },
    showPassword2() {
       if (this.type2 === "password") {
          this.type2 = "text";
          this.hidden2 = "ni ni-lock-circle-open";
       } else {
          this.type2 = "password";
          this.hidden2 = "ni ni-key-25";
       }
    },
    submitEditForm() {
      if (this.forms.edit.new_password != this.forms.edit.new_password_repeat) {
        this.$swal(
          'Hata',
          "Yeni parola ve tekrarını aynı giriniz.",
          'error');
      } else if (this.forms.edit.password == this.forms.edit.new_password) {
        this.$swal(
          'Hata',
          "Yeni parola ile mevcut parola aynı olamaz.",
          'error');
      } else {
        console.log(this.forms.edit);
        UserService.updatePassword(this.forms.edit).then(
          (response) => {
            this.$swal(
              'Başarılı',
              response.message,
              'success');

            this.modals.edit = false;
          }
        ).catch(error => {
          this.$swal(
            'Hata',
            error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
            'error');
        });
      }

    },
  },
};
</script>
