<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-2"
                  >
                    <div>
                      <span class="heading">{{board_photos.total}}</span>
                      <span class="description">Pano Fotoğrafı</span>
                    </div>
                    <div>
                      <span class="heading" v-if="instance.kilometerage">{{Number(instance.kilometerage).toLocaleString()}}</span>
                      <span class="description">Kilometre</span>
                    </div>
                    <div>
                      <span class="heading">{{part_photos.total}}</span>
                      <span class="description">Hasar Fotoğrafı</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <div class="text-center">
                <h3>
                 {{instance.model}} {{instance.make_name}}
                </h3>
                <div class="h4 font-weight-300">
                  {{instance.trim_name}}
                </div>
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">RENK</span><br>{{instance.color_name}}
                </div>
                <hr class="my-4" />
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">ŞASİ NO</span><br>{{instance.vin}}
                </div>
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">MOTOR NO</span><br>{{instance.engine_number}}
                </div>
                <base-button type="primary" size="sm" @click="initModalEdit();"><i class="ni ni-check-bold"></i>Bilgileri Güncelle</base-button>
                <hr class="my-4" />
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">BAŞLANGIÇ TARİHİ</span><br>{{instance.created_at}}
                </div>
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">BİTİŞ TARİHİ</span><br>{{instance.escalated_at ? instance.escalated_at : '-'}}
                </div>
                <hr class="my-4" />
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">ŞUBE</span><br>{{instance.branch_name}}
                </div>
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">PERSONEL</span><br>{{instance.escalated_member}}
                </div>
                <div class="h4 font-weight-300">
                  <span class="font-weight-700 text-xs">RAPOR TÜRÜ</span><br>{{instance.report_title}}
                </div>
                <hr class="my-4" v-if="instance.transfer_at" />
                <div class="h4 font-weight-300" v-if="instance.transfer_at">
                  <span class="font-weight-700 text-xs">TRANSFER TARİHİ</span><br>{{instance.transfer_at}}
                </div>
              </div>
            </div>
          </div>
          <div class="card card-profile shadow mt-5">
            <div class="card-body pt-0 pt-md-4">
              <div class="text-center">
                <h3 class="mb-4">
                 Pano Fotoğrafları
                </h3>
                <div class="image-gallery">
                  <div class="row">
                    <div
                      v-for="(img, index) in board_imgs"
                      :key="index"
                      class="pic col-xl-6"
                      @click="() => showImgBoard(index)"
                    >
                      <img :src="typeof img === 'string' ? img : img.src" />
                    </div>
                  </div>
                </div>
                <base-button type="primary" size="sm" @click="downloadBoardPhotos()" v-if="board_imgs.length > 0"><i class="ni ni-cloud-download-95"></i> İndir</base-button>
              </div>
            </div>
          </div>
          <div class="card card-profile shadow mt-5">
            <div class="card-body pt-0 pt-md-4">
              <div class="text-center">
                <h3 class="mb-4">
                 Hasar Fotoğrafları
                </h3>
                <div class="image-gallery">
                  <div class="row">
                    <div
                      v-for="(img, index) in part_imgs"
                      :key="index"
                      class="pic col-xl-6"
                      @click="() => showImgPart(index)"
                    >
                      <img :src="typeof img === 'string' ? img : img.src" />
                    </div>
                  </div>
                </div>
                <base-button type="primary" size="sm" @click="downloadPartPhotos()" v-if="part_imgs.length > 0"><i class="ni ni-cloud-download-95"></i> İndir</base-button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-5">
                    <h1 class="mb-0">
                      {{instance.plate_number}}
                      <span class="badge text-xs" v-if="instance.status" :class="`badge-${instance.status.class}`">{{instance.status.title}}</span> 
                    </h1>
                  </div>
                  <div class="col text-right">
                      <base-button type="info" size="sm" v-if="instance.pdf_report" @click="openReport"> <i class="ni ni-bullet-list-67"></i> Raporu Yazdır</base-button>
                      <base-button type="primary" size="sm" @click="modals.confirm = true;"><i class="ni ni-check-bold"></i>Oto.net Transfer</base-button>
                      <base-button type="success" size="sm" @click="revertStatus"  v-if="instance && instance.status && instance.status.code != 'pending'"><i class="ni ni-check-bold"></i>Güncellemeye Aç</base-button>
                  </div>
                </div>
              </div>
            </template>
            <div v-for="category in report" :key="category.id">
              <h6 class="heading-small text-muted mb-4">{{category.title}}</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="table-responsive">
                    <base-table thead-classes="thead-light" :data="category.parts" :striped="true">
                      <template v-slot:columns>
                        <th>Parça Adı</th>
                        <th>Durum</th>
                      </template>

                      <template v-slot:default="row">
                        <th scope="row">
                          {{ row.item.title }}
                        </th>
                        <td>
                          {{ row.item.selected ? row.item.selected.join(', ') : '-' }}
                        </td>
                      </template>
                    </base-table>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
            </div>
            <h6 class="heading-small text-muted mb-4">AÇIKLAMA</h6>
            <div class="pl-lg-4">
              <div class="row">
                <p>{{instance.description}}</p>
              </div>
            </div>
          </card>
        </div>
        <modal v-model:show="modals.confirm">
           <template v-slot:header>
              <h5 class="modal-title">Oto.net Transfer</h5>
           </template>
            <div>
              <strong>{{instance.plate_number}} plakalı aracı transfer etmek istediğinize emin misiniz?</strong>
            </div>
           <template v-slot:footer>
             <base-button type="primary" size="md" @click="otonetTransfer">Evet</base-button>
             <base-button type="secondary" size="md" @click="modals.confirm = false">Kapat</base-button>
           </template>
        </modal>
        <modal v-model:show="modals.edit">
           <template v-slot:header>
              <h5 class="modal-title">Araç Bilgilerini Düzenle</h5>
           </template>
           <div>
            <Form @submit="submitEditForm" role="form" :validation-schema="schema">
              <label class="form-control-label">Plaka</label>
              <Field name="plate_number" type="text" class="input-group-alternative mb-3 form-control" placeholder="Plaka" v-model="forms.edit.plate_number"
              />
              <ErrorMessage name="engine_number" class="error-feedback" />
              <label class="form-control-label">Model Yılı</label>
              <Field name="model" type="text" class="input-group-alternative mb-3 form-control" placeholder="Model Yılı" v-model="forms.edit.model"
              />
              <ErrorMessage name="model" class="error-feedback" />
              <label class="form-control-label">Şasi No</label>
              <Field name="vin" type="text" class="input-group-alternative mb-3 form-control" placeholder="Şasi No" v-model="forms.edit.vin"
              />
              <ErrorMessage name="vin" class="error-feedback" />
              <label class="form-control-label">Motor No</label>
              <Field name="engine_number" type="text" class="input-group-alternative mb-3 form-control" placeholder="Motor No" v-model="forms.edit.engine_number"
              />
              <ErrorMessage name="engine_number" class="error-feedback" />
            </Form>
           </div>
           <template v-slot:footer>
             <base-button type="secondary" @click="modals.edit = false">Kapat</base-button>
             <base-button type="primary" @click="submitEditForm()">Güncelle</base-button>
           </template>
        </modal>
        <VueElementLoading
                :spinner="spinnerKind"
                :size="spinnerSize"
                :duration="spinnerDuration"
                :color="spinnerColor"
                :active="fullscreen"
                is-full-screen
              />
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="board_visible"
    :imgs="board_imgs"
    :index="board_index"
    @hide="handleHide"
  ></vue-easy-lightbox>
  <vue-easy-lightbox
    :visible="part_visible"
    :imgs="part_imgs"
    :index="part_index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</template>
<script>
import ExpertiseService from "../services/expertise.service";
import VueEasyLightbox from 'vue-easy-lightbox';
import VueElementLoading from "vue-element-loading";
import {computed} from 'vue';
import {useStore} from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  components: {
    VueEasyLightbox,
    VueElementLoading,
    Form,
    Field,
    ErrorMessage
  },
  created: function () {
    ExpertiseService.get(this.$route.params.id).then(
      (instance) => {
        this.instance = instance.data;
    });
    ExpertiseService.getReport(this.$route.params.id).then(
      (report) => {
        this.report = report.data;
    });
    ExpertiseService.getBoardPhotos(this.$route.params.id).then(
      (photos) => {
        this.board_photos = photos.data;
        photos.data.data.forEach((photo) => {
          this.board_imgs.push({src: photo.url, title: photo.title})
        });
    });
    ExpertiseService.getPartPhotos(this.$route.params.id).then(
      (photos) => {
        this.part_photos = photos.data;
        photos.data.data.forEach((photo) => {
          this.part_imgs.push({src: photo.url, title: photo.title})
        });
    });
  },
  data() {
    const store = useStore();

    let user = computed(function () {
      return store.state.auth.user
    });

    const schema = yup.object().shape({
      plate_number: yup.string().required("Plaka zorunludur").nullable(),
      model: yup.string().required("Model Yılı zorunludur").nullable(),
      vin: yup.string().required("Şasi No zorunludur").nullable(),
      engine_number: yup.string().required("Motor No zorunludur").nullable(),
    });

    return {
      instance: [],
      report: [],
      board_photos: [],
      part_photos: [],
      board_visible: false,
      board_index: 0,
      part_visible: false,
      part_index: 0,
      board_imgs: [],
      part_imgs: [],
      user: user,
      fullscreen: false,
      spinnerKind: 'bar-fade-scale',
      spinnerColor: '#2DCE89',
      spinnerSize: '90',
      spinnerDuration: '1',
      loading: false,
      modals: {
        confirm: false,
        edit: false 
      },
      forms: {
        edit: {
          model: '',
          vin: '',
          engine_number: ''
        }
      },
      schema
    };
  },
  methods: {
    showImgBoard(index) {
      this.board_index = index
      this.board_visible = true
    },
    showImgPart(index) {
      this.part_index = index
      this.part_visible = true
    },
    handleHide() {
      this.board_visible = false
      this.part_visible = false
    },
    downloadBoardPhotos() {
      window.open(process.env.VUE_APP_API + "/api/vehicles/instances/" + this.$route.params.id + "/board-photos/download?access_token=" + this.user.access_token);
    },
    downloadPartPhotos() {
      window.open(process.env.VUE_APP_API + "/api/vehicles/instances/" + this.$route.params.id + "/part-photos/download?access_token=" + this.user.access_token);
    },
    otonetTransfer() {
      this.modals.confirm = false;
      this.fullscreen = true;
      ExpertiseService.otonetTransfer(this.$route.params.id).then(
        (response) => {
          this.$swal(
            'Başarılı',
            response.message,
            'success');
          this.fullscreen = false;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
        this.fullscreen = false;
      });
    },
    revertStatus() {
      ExpertiseService.revertStatus(this.$route.params.id).then(
        (response) => {
          ExpertiseService.get(this.$route.params.id).then(
            (instance) => {
              this.instance = instance.data;
          });

          this.$swal(
            'Başarılı',
            response.message,
            'success');
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Durum güncelleme başarısız oldu.',
          'error');
      });
    },
    initModalEdit() {
      this.modals.edit = true;

      this.forms.edit.plate_number = this.instance['plate_number'];
      this.forms.edit.model = this.instance['model'];
      this.forms.edit.vin = this.instance['vin'];
      this.forms.edit.engine_number = this.instance['engine_number'];
    },
    submitEditForm() {
      this.fullscreen = true;
      ExpertiseService.editExpertise(this.$route.params.id, this.forms.edit.model, this.forms.edit.vin, this.forms.edit.engine_number).then(
        () => {
          ExpertiseService.editPlateNumber(this.$route.params.id, this.forms.edit.plate_number).then(
            (response) => {
              this.fullscreen = false;
              this.modals.edit = false;
              this.$swal(
                'Başarılı',
                response.message,
                'success');
              ExpertiseService.get(this.$route.params.id).then(
                (instance) => {
                  this.instance = instance.data;
              });
            }
          ).catch(error => {
            this.$swal(
              'Hata',
              error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
              'error');
            this.fullscreen = false;
          });
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
        this.fullscreen = false;
      });
    },
    openReport() {
      if (this.instance.pdf_report) {
        window.open(this.instance.pdf_report, '_blank');
      }
    }
  },
  mounted() {
  },
};
</script>
<style></style>
