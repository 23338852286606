import api from './api';

class UserService {
  get() {
    return api.get('/api/account');
  }
  getMembers() {
    return api.get('/api/members');
  }
  updateMember(payload) {
    return api.put('/api/members/' + payload.id, payload);
  }
  createMember(payload) {
    return api.post('/api/members', payload);
  }
  removeMember(id) {
    return api.delete('/api/members/' + id);
  }
  updatePassword(password) {
    return api.put('/api/members/password', password);
  }
}

export default new UserService();