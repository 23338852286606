import axiosInstance from "./api";
import TokenService from "./token.service";
import NProgress from 'nprogress'

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      NProgress.start()
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      NProgress.done()
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/oauth/v2/token" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosInstance.post("/oauth/v2/token", {
              refresh_token: TokenService.getLocalRefreshToken(),
              grant_type: 'refresh_token',
              client_id: process.env.VUE_APP_CLIENT_ID,
              client_secret: process.env.VUE_APP_CLIENT_SECRET
            });

            const { access_token } = rs.data;
            const { refresh_token } = rs.data;

            store.dispatch('auth/refreshToken', refresh_token);
            store.dispatch('auth/accessToken', access_token);

            TokenService.updateLocalAccessToken(access_token);
            TokenService.updateLocalRefreshToken(refresh_token);

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      NProgress.done()

      return Promise.reject(err);
    }
  );
};

export default setup;