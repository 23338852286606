import api from './api';

class BranchService {
  get() {
    return api.get('/api/companies/branches');
  }
  update(payload) {
    return api.put('/api/companies/branches/' + payload.id, payload);
  }
  create(payload) {
    return api.post('/api/companies/branches', payload);
  }
  remove(id) {
    return api.delete('/api/companies/branches/' + id);
  }
}

export default new BranchService();