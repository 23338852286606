<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        
      </div>
    </base-header>
    <div class="container-fluid mt--7">
    <div class="row">
      <div class="col">
        <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
          <div
            class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent' : ''"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Kullanıcı Listesi
                </h3>
              </div>
              <div class="col text-right">
                <base-button type="primary" size="sm" @click="initModalCreate()">Ekle</base-button>
              </div>
            </div>
          </div>

          <div class="table-responsive table-overflowed">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableData"
            >
              <template v-slot:columns>
                <th>Adı</th>
                <th>Soyadı</th>
                <th>Cep Telefonu</th>
                <th>Şube</th>
                <th></th>
              </template>

              <template v-slot:default="row">
                <th scope="row">
                  {{ row.item.name }}
                </th>
                <td>
                  {{ row.item.surname }}
                </td>
                <td>
                  {{ row.item.mobile }}
                </td>
                <td>
                  {{ row.item.branch_name }}
                </td>

                <td class="text-right">
                  <base-dropdown class="dropdown" position="right">
                    <template v-slot:title>
                      <a
                        class="btn btn-sm btn-icon-only text-light"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </a>
                    </template>

                    <a class="dropdown-item" @click="initModalEdit(row.item)">Düzenle</a>
                    <a class="dropdown-item" href="#" @click="removeMember(row.item.id)">Kaldır</a>
                  </base-dropdown>
                </td>
              </template>
            </base-table>
            <modal v-model:show="modals.edit">
               <template v-slot:header>
                  <h5 class="modal-title">Kullanıcı Düzenle</h5>
               </template>
               <div>
                <form role="form">
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Ad"
                    v-model="forms.edit.name"
                  >
                  </base-input>
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Soyad"
                    v-model="forms.edit.surname"
                  >
                  </base-input>
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Cep Telefonu"
                    v-model="forms.edit.mobile"
                  >
                  </base-input>
                  <select class="custom-select custom-select-lg mb-3" v-model="forms.edit.branch_id">
                    <option>Seçiniz</option>
                    <option v-for="branch in branches" :key="branch.id" v-bind:value="branch.id">{{branch.name}}</option>
                  </select>
                </form>
               </div>
               <template v-slot:footer>
                 <base-button type="secondary" @click="modals.edit = false">Kapat</base-button>
                 <base-button type="primary" @click="submitEditForm()">Güncelle</base-button>
               </template>
            </modal>
            <modal v-model:show="modals.create">
               <template v-slot:header>
                  <h5 class="modal-title">Kullanıcı Ekle</h5>
               </template>
               <div>
                <form role="form">
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Ad"
                    v-model="forms.create.name"
                  >
                  </base-input>
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Soyad"
                    v-model="forms.create.surname"
                  >
                  </base-input>
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Cep Telefonu"
                    v-model="forms.create.mobile"
                  >
                  </base-input>
                  <select class="custom-select custom-select-lg mb-3" v-model="forms.create.branch_id">
                    <option v-for="branch in branches" :key="branch.id" v-bind:value="branch.id">{{branch.name}}</option>
                  </select>
                </form>
               </div>
               <template v-slot:footer>
                 <base-button type="secondary" @click="modals.create = false">Kapat</base-button>
                 <base-button type="primary" @click="submitCreateForm()">Ekle</base-button>
               </template>
            </modal>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import UserService from "../services/user.service";
import BranchService from "../services/branch.service";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    title: String
  },
  components: {
    Modal
  },
  created: function () {
    UserService.getMembers().then(
      (members) => {
        this.tableData = members.data;
    });
    BranchService.get().then(
      (branches) => {
        this.branches = branches.data;
    });
  },
  data() {
    return {
      tableData: [],
      branches: [],
      modals: {
        create: false,
        edit: false
      },
      forms: {
        create: {
          name: null,
          surname: null,
          mobile: null,
          branch_name: null,
          branch_id: null
        },
        edit: {
          id: null,
          name: null,
          surname: null,
          mobile: null,
          branch_name: null,
          branch_id: null
        }
      }
    };
  }, 
  methods: {
    initModalCreate() {
        this.modals.create = true;
    },
    initModalEdit(item) {
        this.modals.edit = true;
        this.forms.edit = item;
    },
    submitEditForm() {
      UserService.updateMember(this.forms.edit).then(
        (response) => {
          this.$swal(
            'Başarılı',
            response.message,
            'success');

          this.modals.edit = false;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
      });
    },
    submitCreateForm() {
      UserService.createMember(this.forms.create).then(
        (response) => {
          this.$swal(
            'Başarılı',
            response.message,
            'success');

          UserService.getMembers().then(
            (members) => {
              this.tableData = members.data;
          });

          this.modals.create = false;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
      });
    },
    removeMember(id) {
      UserService.removeMember(id).then(
        (response) => {
          this.$swal(
            'Başarılı',
            response.message,
            'success');

          UserService.getMembers().then(
            (members) => {
              this.tableData = members.data;
          });

          this.modals.create = false;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen alanların doğru ve dolu olduğundan emin olunuz.',
          'error');
      });
    }
  }
};
</script>
<style></style>
