<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        
      </div>
    </base-header>
    <div class="container-fluid mt--7">
        <div class="card shadow">
          <div class="card-header border-0">
            <h3>
              Araç Bilgilerini Güncelle
            </h3>
          </div>

          <div class="card-body">
              <Form @submit="importVehicleInstance" :validation-schema="schema">
                <div class="row">
                  <div class="col-sm-4">
                    <Field name="file" type="file" ref="fileInput" class="input-group-alternative form-control  mb-3" :key="fileInputKey" />
                    <ErrorMessage name="file" class="error-feedback" />
                  </div>
                  <div class="col-sm-2">
                    <button class="btn btn-primary" :disabled="loading" @click="this.visible = false; this.updatedVehicles = [];">
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span>
                      <span>Güncelle</span>
                    </button>
                  </div>
                </div>
              </Form>
              <div class="table-responsive" v-if="visible">
                <h4>
                  Güncellenen Araçlar
                </h4>
                <base-table
                  class="table align-items-center table-flush"
                  tbody-classes="list"
                  :thead-classes="'thead-dark'"
                  :class="'table-dark'"
                  :data="updatedVehicles"
                >
                  <template v-slot:columns>
                    <th>Plaka</th>
                    <th>Model</th>
                    <th>Şasi</th>
                    <th>Motor No</th>
                    <th>Durum</th>
                  </template>

                  <template v-slot:default="row">
                    <th scope="row">
                      {{ row.item.plate_number }}
                    </th>
                    <td>
                      {{ row.item.model }}
                    </td>
                    <td>
                      {{ row.item.vin }}
                    </td>
                    <td >
                      {{ row.item.engine_number }}
                    </td>
                    <td v-if="row.item.success">
                      <span class="badge badge-dot" :class="`badge-success`">
                        <i :class="`bg-success`"></i>
                        <span class="status">{{ row.item.message }}</span>
                      </span>
                    </td>
                    <td v-else>
                      <span class="badge badge-dot" :class="`badge-warning`">
                        <i :class="`bg-warning`"></i>
                        <span class="status">{{ row.item.message }}</span>
                      </span>
                    </td>
                  </template>
                </base-table>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ExpertiseService from "../services/expertise.service";

export default {
  name: "VehicleInstanceUpdate",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      file: yup.mixed().required('Lütfen dosya seçiniz.'),
    });

    return {
      updatedVehicles: [],
      visible: false,
      loading: false,
      message: "",
      schema,
      fileInputKey: 0
    };
  }, 
  methods: {
    importVehicleInstance(file) {
      ExpertiseService.importVehicleInstance(file).then(
        (response) => {
          this.updatedVehicles = response.data.data.updatedVehicles;
          this.visible = true;
        }
      ).catch(error => {
        this.$swal(
          'Hata',
          error.response.data.message ? error.response.data.message : 'Lütfen dosyanın içeriğini kontrol ediniz.',
          'error');
      });
      this.$refs.fileInput.reset();
      this.fileInputKey++;
    },
  }
};
</script>
<style></style>
